import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
} from '@angular/core';
import {Biolink, BiolinkAppearance} from '../biolink';
import {DomSanitizer} from '@angular/platform-browser';
import {formatEmbedURL} from 'spotify-uri';
import {Settings} from '@common/core/config/settings.service';
import {removeProtocol} from '@common/core/utils/remove-protocol';
import {LazyLoaderService} from '@common/core/utils/lazy-loader.service';
import getVideoId from 'get-video-id';
import {BackgroundUrlPipe} from '@common/core/ui/format-pipes/background-url.pipe';
import {GoogleFontService} from '@common/shared/form-controls/google-font-selector/google-font.service';

@Component({
    selector: 'biolink-page',
    templateUrl: './biolink-page.component.html',
    styleUrls: ['./biolink-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BiolinkPageComponent implements OnChanges {
    @Input() biolink: Biolink;
    @Input() appearance: BiolinkAppearance;

    get dynamicOrStoredAppearance(): BiolinkAppearance {
        return this.appearance || this.biolink.appearance?.config;
    }

    constructor(
        private sanitizer: DomSanitizer,
        public settings: Settings,
        private lazyLoader: LazyLoaderService,
        private el: ElementRef<HTMLElement>,
        private bgUrlPipe: BackgroundUrlPipe,
        private googleFonts: GoogleFontService
    ) {}

    ngOnChanges(): void {
        if (this.biolink) {
            this.setBackground();
            this.setBtnStyle();
            this.setFontStyle();
            this.initAnimations();

            if (this.biolink.content.find(c => c.type === 'tiktokEmbed')) {
                this.lazyLoader.loadAsset('https://www.tiktok.com/embed.js', {
                    type: 'js',
                });
            }
        }
    }

    trustUrl(url: string) {
        return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }

    trustHtml(html: string) {
        return this.sanitizer.bypassSecurityTrustHtml(html);
    }

    getSpotifyEmbedUrl(url: string) {
        return this.trustUrl(formatEmbedURL(url));
    }

    getTwitchEmbedUrl(url) {
        let embedUrl: string;
        const parsedUrl = new URL(url);
        const channelOrClipId = parsedUrl.pathname.split('/').pop().trim();
        const siteUrl = removeProtocol(this.settings.getBaseUrl(true));
        if (url.includes('clip')) {
            embedUrl = `https://clips.twitch.tv/embed?clip=${channelOrClipId}`;
        } else {
            embedUrl = `https://player.twitch.tv/?channel=${channelOrClipId}`;
        }
        return this.trustUrl(`${embedUrl}&parent=${siteUrl}`);
    }

    getTiktokVideoId(url: string): string {
        return new URL(url).pathname.split('/').pop().trim();
    }

    getVimeoEmbedId(url: string): string {
        if (!url) return null;
        return getVideoId(url).id;
    }

    private setBackground() {
        const appearance = this.dynamicOrStoredAppearance;
        if (appearance?.bgConfig) {
            this.el.nativeElement.style.backgroundImage =
                this.bgUrlPipe.transform(appearance.bgConfig.image || null);
            this.el.nativeElement.style.backgroundColor =
                appearance.bgConfig.color || null;
            this.el.nativeElement.style.backgroundAttachment =
                appearance.bgConfig.attachment || null;
            this.el.nativeElement.style.backgroundSize =
                appearance.bgConfig.size || null;
            this.el.nativeElement.style.backgroundRepeat =
                appearance.bgConfig.repeat || null;
            this.el.nativeElement.style.backgroundPosition =
                appearance.bgConfig.position || null;
            this.el.nativeElement.style.color =
                appearance.bgConfig.textColor || null;
        }
    }

    private setBtnStyle() {
        const appearance = this.dynamicOrStoredAppearance;
        // don't override color from background config with undefined
        if (appearance?.btnConfig?.color) {
            this.el.nativeElement.style.color = appearance?.btnConfig?.color;
        }
    }

    private setFontStyle() {
        const appearance = this.dynamicOrStoredAppearance;
        if (appearance?.fontConfig?.family) {
            this.el.nativeElement.style.fontFamily =
                appearance.fontConfig.family;
            this.googleFonts.loadIntoDom(
                [{family: appearance.fontConfig.family}],
                'biolink-page'
            );
        } else {
            this.el.nativeElement.style.fontFamily = null;
        }
    }

    originalOrder = (): number => {
        return 0;
    };

    private initAnimations() {
        if (
            this.biolink.content.some(
                c => c.model_type === 'link' && c.animation
            )
        ) {
            this.lazyLoader.loadAsset(
                'https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css',
                {type: 'css', id: 'animate.css'}
            );
        }
    }
}
